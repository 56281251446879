import { useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import useAppContext from 'app/store/useAppContext'
import { setIsGiftModal } from 'app/store/actions'
import { ROUTES } from 'shared/config/routes'
import { CookiesService } from 'shared/services/Cookies.service'

export const useShowGiftModal = () => {
  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  const {
    store: {
      modals: {
        isAffirmationModal,
        isSuccessAffirmationModal,
        isAcceptCookiesModal,
        isCookiesModal,
        documentModalType,
        isPrivacyPolicyModal,
      },
    },
    dispatch,
  } = useAppContext()

  const giftCookie = CookiesService.get({ name: 'gift_modal' }) === 'accepted'

  const isOpenGiftModal =
    !isAffirmationModal &&
    !isSuccessAffirmationModal &&
    !isAcceptCookiesModal &&
    !isCookiesModal &&
    !isRegisteredPage &&
    !documentModalType &&
    !isPrivacyPolicyModal

  const resetTimer = useCallback(() => {
    if (isOpenGiftModal && !giftCookie) {
      const timerId = setTimeout(() => {
        dispatch(setIsGiftModal(true))
        CookiesService.set({ name: 'gift_modal', token: 'accepted' })
      }, 300000) // 5 minutes

      return () => clearTimeout(timerId)
    }
  }, [isOpenGiftModal, giftCookie, dispatch])

  useEffect(() => {
    if (isOpenGiftModal && !giftCookie) {
      if (isRegisteredPage) return

      const handleUserActivity = () => resetTimer()

      document.addEventListener('mousemove', handleUserActivity)
      document.addEventListener('mousedown', handleUserActivity)
      document.addEventListener('keydown', handleUserActivity)
      document.addEventListener('scroll', handleUserActivity)

      const cleanup = resetTimer() || (() => {})

      return () => {
        document.removeEventListener('mousemove', handleUserActivity)
        document.removeEventListener('mousedown', handleUserActivity)
        document.removeEventListener('keydown', handleUserActivity)
        document.removeEventListener('scroll', handleUserActivity)
        cleanup()
      }
    }
  }, [isOpenGiftModal, giftCookie, resetTimer, isRegisteredPage])
}
