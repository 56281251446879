import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Header from 'widgets/header'
import Footer from 'widgets/footer'
import { useSetUserSearchParams } from 'entities/user/use-cases/useSetUserParams'
import useSetUserApiData from 'entities/user/use-cases/useSetUserApiData'
import useSetIsMobileModal from 'features/modal/use-cases/useSetIsMobileModal'
import AcceptCookieModal from 'features/cookie/AcceptCookieModal'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import { GiftModal, useShowGiftModal } from 'features/gift-modal'
import { ROUTES } from 'shared/config/routes'

export const MainLayout = () => {
  const { isMobileModal, isSomeModalsOpened } = useIsMobileModal()

  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  useSetIsMobileModal()
  useSetUserSearchParams()
  useSetUserApiData()
  useShowGiftModal()

  return (
    <div className="app">
      <Header />
      <main
        className={classNames(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Outlet />
      </main>

      {!isRegisteredPage && <Footer />}

      <AcceptCookieModal />
      <GiftModal />
    </div>
  )
}
