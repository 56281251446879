type resultsDataType = {
  id: number
  fullName: string
  transactionNumber: string
  transactionValue: string
  transactionTime: string
}

const mldClients: resultsDataType[] = [
  {
    id: 1,
    fullName: 'Дончук E.M.',
    transactionNumber: 'Дивиденды ***7321',
    transactionValue: '+2 475.89 MDL',
    transactionTime: '09:18',
  },
  {
    id: 2,
    fullName: 'Григора O.B.',
    transactionNumber: 'Дивиденды ***4956',
    transactionValue: '+3 689.45 MDL',
    transactionTime: '14:37',
  },
  {
    id: 3,
    fullName: 'Никулес Н.У.',
    transactionNumber: 'Дивиденды ***1685',
    transactionValue: '+1 932.76 MDL',
    transactionTime: '10:45',
  },
  {
    id: 4,
    fullName: 'Ионеску А.М.',
    transactionNumber: 'Дивиденды ***2598',
    transactionValue: '+3 201.34 MDL',
    transactionTime: '16:20',
  },
  {
    id: 5,
    fullName: 'Гаидар З.Ж.',
    transactionNumber: 'Дивиденды ***6043',
    transactionValue: '+2 810.67 MDL',
    transactionTime: '12:03',
  },
  {
    id: 6,
    fullName: 'Сирбу А.К.',
    transactionNumber: 'Дивиденды ***7489',
    transactionValue: '+2 101.23 MDL',
    transactionTime: '11:29',
  },
  {
    id: 7,
    fullName: 'Циботар С.С.',
    transactionNumber: 'Дивиденды ***3712',
    transactionValue: '+3 578.12 MDL',
    transactionTime: '15:45',
  },
  {
    id: 8,
    fullName: 'Мейрамы Д.Д.',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+2 659.78 MDL',
    transactionTime: '13:10',
  },
  {
    id: 9,
    fullName: 'Руснак Я.Б.',
    transactionNumber: 'Дивиденды ***5137',
    transactionValue: '+1 450.56 MDL',
    transactionTime: '08:57',
  },
  {
    id: 10,
    fullName: 'Андреев Р.О.',
    transactionNumber: 'Дивиденды ***1956',
    transactionValue: '+3 122.01 MDL',
    transactionTime: '17:12',
  },
  {
    id: 11,
    fullName: 'Калиева Ж.П.',
    transactionNumber: 'Дивиденды ***6743',
    transactionValue: '+2 767.90 MDL',
    transactionTime: '14:02',
  },
  {
    id: 12,
    fullName: 'Давыдов Ш.А.',
    transactionNumber: 'Дивиденды ***3621',
    transactionValue: '+3 888.33 MDL',
    transactionTime: '10:35',
  },
  {
    id: 13,
    fullName: 'Алиева У.В.',
    transactionNumber: 'Дивиденды ***8074',
    transactionValue: '+1 655.44 MDL',
    transactionTime: '12:46',
  },
  {
    id: 14,
    fullName: 'Солома А.Ч.',
    transactionNumber: 'Дивиденды ***4298',
    transactionValue: '+3 999.01 MDL',
    transactionTime: '16:58',
  },
  {
    id: 15,
    fullName: 'Ганжа Т.Б.',
    transactionNumber: 'Дивиденды ***7512',
    transactionValue: '+2 433.55 MDL',
    transactionTime: '09:23',
  },
  {
    id: 16,
    fullName: 'Циботар Д.У.',
    transactionNumber: 'Дивиденды ***5864',
    transactionValue: '+1 570.22 MDL',
    transactionTime: '11:55',
  },
  {
    id: 17,
    fullName: 'Муканы П.П.',
    transactionNumber: 'Дивиденды ***9430',
    transactionValue: '+2 865.77 MDL',
    transactionTime: '15:14',
  },
  {
    id: 18,
    fullName: 'Сирбу К.К.',
    transactionNumber: 'Дивиденды ***3102',
    transactionValue: '+3 321.88 MDL',
    transactionTime: '13:28',
  },
  {
    id: 19,
    fullName: 'Матей Г.К.',
    transactionNumber: 'Дивиденды ***1786',
    transactionValue: '+1 977.66 MDL',
    transactionTime: '17:35',
  },
  {
    id: 20,
    fullName: 'Касенова З.Ж.',
    transactionNumber: 'Дивиденды ***6975',
    transactionValue: '+3 123.09 MDL',
    transactionTime: '08:42',
  },
]

export const data = mldClients
