import { useEffect } from 'react'

import useAppContext from 'app/store/useAppContext'
import {
  setIsMobile,
  setIsMobile500,
  setIsMobileSmall,
} from 'app/store/actions'
import { useIsMobileScreen } from 'shared/utils/hooks'

export default function useSetIsMobileModal() {
  const isMobile = useIsMobileScreen(768)
  const isMobileSmall = useIsMobileScreen(655)
  const isMobile500 = useIsMobileScreen(500)
  const { dispatch } = useAppContext()

  useEffect(() => {
    dispatch(setIsMobile(isMobile))
    dispatch(setIsMobileSmall(isMobileSmall))
    dispatch(setIsMobile500(isMobile500))
  }, [isMobile, isMobileSmall, isMobile500])
}
