import useAppContext from 'app/store/useAppContext'
import { setIsCookiesModal } from 'app/store/actions'
import { Modal } from 'shared/ui/modal'
import CookiesPolicyText from '../../text/CookiesPolicyText'

const customModalStyles = {
  overlay: {
    zIndex: 1,
  },
  content: {
    zIndex: 2,
    maxHeight: '100vh',
    overflow: 'auto',
  },
}
const customMobileSmallModalStyles = {
  overlay: {
    zIndex: 1,
  },
  content: {
    zIndex: 2,
    width: '100vw',
    height: `calc(100vh - 100px)`,
    margin: '40px 0 0 0',
    overflow: 'auto',
  },
}

export default function CookiesPolicyModal() {
  const {
    store: {
      isMobileSmall,
      modals: { isCookiesModal },
    },
    dispatch,
  } = useAppContext()

  const closeModal = () => {
    dispatch(setIsCookiesModal(false))
  }

  return (
    <Modal
      style={isMobileSmall ? customMobileSmallModalStyles : customModalStyles}
      isOpen={isCookiesModal}
      isCloseIcon={!isMobileSmall}
      onRequestClose={closeModal}
      content={<CookiesPolicyText />}
    />
  )
}
